import { useEffect, useState, useCallback } from 'react';
import { SelectChangeEvent } from '@mui/material';

import api from 'api';

import { TExpenseReport } from 'utils/types';
import { sortReportItemsByDrBudgetsCatagoryName } from '../helpers';

const useReport = (id: number) => {
  const [report, setReport] = useState<TExpenseReport | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [updatingRowId, setUpdatingRowId] = useState<number | null>(null);

  const handleSortReport = useCallback((data: TExpenseReport | null) => {
    if (!data) return null;
    const sortedReport = sortReportItemsByDrBudgetsCatagoryName(data);
    return setReport(sortedReport);
  }, []);

  useEffect(() => {
    const fetchReport = async () => {
      try {
        setIsLoading(true);
        const { data } = await api.expense.getReport(id);

        const sortedData = sortReportItemsByDrBudgetsCatagoryName(data);

        setReport(sortedData);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchReport();
  }, [id]);

  const updatePeportItemCategory = useCallback(
    async (e: SelectChangeEvent<number>, rowId: number) => {
      if (!report) return;

      try {
        e.stopPropagation();
        setUpdatingRowId(rowId);
        const id = Number(e.target.value);

        const { data: updatedItem } = await api.expense.changeCategory({
          categoryId: id,
          expenseItemId: rowId,
          reportType: report.type,
        });

        setReport((prevReport) =>
          prevReport
            ? {
                ...prevReport,
                items: prevReport.items.map((item) => {
                  if (rowId === item.id) {
                    return {
                      ...updatedItem,
                      isUpdated: true,
                    };
                  }
                  return item;
                }),
              }
            : null,
        );
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      } finally {
        setUpdatingRowId(null);
      }
    },
    [report],
  );

  return {
    report,
    updatePeportItemCategory,
    isLoading,
    updatingRowId,
    handleSortReport,
  };
};

export default useReport;
