import { memo } from 'react';
import { Box } from '@mui/system';
import { CircularProgress } from '@mui/material';

const CellLoader = () => (
  <Box sx={{ width: '90%' }}>
    <CircularProgress />
  </Box>
);

export default memo(CellLoader);
