import { useEffect, useState } from 'react';

import api from 'api';

import useAppContext from 'utils/hooks/useAppContext';
import { TExpenseFinalReport, TExpenseReport } from 'utils/types';
import { createReport, createSubtotals } from 'utils/xlsx';
import {
  getNameForSorting,
  sortReportItemsByDrBudgetsCatagoryName,
  sortStringAlphabetically,
} from 'utils/helpers';
import Loader from 'ui/components/Loader';
import NextButton from 'ui/components/Buttons/NextButton';

import SubtotalItem from './components/SubtotalItem';
import Download from './components/Download';

const FinalReport = ({ onHandleFinish }: { onHandleFinish: () => void }) => {
  const { reportId, highlightedCellsIds } = useAppContext();
  const [data, setData] = useState<TExpenseFinalReport | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!reportId) return;

    const fetchFinalReport = async () => {
      try {
        setIsLoading(true);
        const { data } = await api.expense.getFinalReport(reportId);

        const clonedData: TExpenseFinalReport = structuredClone(data);

        const sortedReportData = sortReportItemsByDrBudgetsCatagoryName(
          clonedData.report,
        );

        const sortedSubtotalsOfDrBudgetsCategories = Object.fromEntries(
          Object.entries(clonedData.subtotalsOfDrBudgetsCategories).sort(
            (a, b) => {
              const nameA = getNameForSorting(a[0]);
              const nameB = getNameForSorting(b[0]);
              return sortStringAlphabetically(nameA, nameB);
            },
          ),
        );

        const resultData: TExpenseFinalReport = {
          ...clonedData,
          report: sortedReportData as TExpenseReport,
          subtotalsOfDrBudgetsCategories: sortedSubtotalsOfDrBudgetsCategories,
        };

        setData(resultData);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFinalReport();
  }, [reportId]);

  if (isLoading) {
    return <Loader />;
  }

  if (!data) {
    return <div className="category-comparison__final-report">No data</div>;
  }

  return (
    <div className="category-comparison__final-report">
      <div className="category-comparison__final-report-container">
        <div className="category-comparison__final-report-subtitle">
          Report sorted by Dr. Budget’s Category
        </div>

        <p className="category-comparison__final-report-helper-text">
          You can download the table sorted by Dr. Budget categories in .csv
          format.
        </p>

        <Download
          onClick={() =>
            createReport(
              data!.report.name,
              data!.report.items,
              highlightedCellsIds,
            )
          }
        />
      </div>

      <div className="category-comparison__final-report-container category-comparison__final-report-amounts">
        <div className="category-comparison__final-report-amounts-wrapper">
          <SubtotalItem
            title="Subtotals of Dr. Budget Categories"
            columnTitle="Dr. Budget Category"
            values={data.subtotalsOfDrBudgetsCategories}
          />
          <SubtotalItem
            title="Subtotals by Account Names"
            columnTitle="Account Names"
            values={data.subtotalsByAccountName}
          />
        </div>
        <p className="category-comparison__final-report-helper-text">
          You can download the table Subtotals of Dr. Budget Categories and
          Subtotals by Account Names in .csv format.
        </p>

        <Download
          onClick={() =>
            createSubtotals(
              data!.subtotalsByAccountName,
              data!.subtotalsOfDrBudgetsCategories,
              data!.report.name,
            )
          }
        />
      </div>
      <div className="category-comparison__final-report-finish">
        <NextButton onClick={onHandleFinish}>Finish</NextButton>
      </div>
    </div>
  );
};

export default FinalReport;
