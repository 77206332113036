import { memo } from 'react';

import logo from 'ui/assets/images/dr-budgets-logo.png';

import StyledHeader from './Header.styles';

const Header = () => (
  <StyledHeader>
    <div className='header-wrapper'>
      <img src={logo} alt="dr-budgets-logo" />
    </div>
  </StyledHeader>
);

export default memo(Header);
