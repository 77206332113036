import axios from 'axios';
import config from 'config';
import { EHttpMethods } from 'utils/types.d';

export const axiosInstance = axios.create({
  baseURL: config.apiUrl,
});

export default <T>({
  url = '/',
  method = EHttpMethods.GET,
  params = {},
  data = {},
  headers = {},
}): Promise<T> =>
  axiosInstance({
    url,
    method,
    headers,
    params,
    data,
  });
