import {
  EHttpMethods,
  TCommonResponseType,
  TCreateReport,
  TExpenseReport,
  TExpenseFinalReport,
  EReportType,
  TExpenseItem,
} from 'utils/types.d';

import makeRequest from './makeRequest';

const basePath = '/expense';
const reportPath = '/report';

export const createReport = (
  data: TCreateReport,
): Promise<TCommonResponseType<TExpenseReport>> => {
  const formData = new FormData();

  const { reportFile, reportType, newFileName } = data;

  formData.append('reportType', reportType);
  formData.append('reportFile', reportFile as string | Blob);
  formData.append('newFileName', newFileName);

  return makeRequest<TCommonResponseType<TExpenseReport>>({
    url: `${basePath}${reportPath}`,
    method: EHttpMethods.POST,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });
};

export const getReport = (
  id: number,
): Promise<TCommonResponseType<TExpenseReport>> =>
  makeRequest<TCommonResponseType<TExpenseReport>>({
    url: `${basePath}${reportPath}/${id}`,
    method: EHttpMethods.GET,
  });

export const updateReportItems = (data: {
  dataToUpdate: { itemId: number; newCategoryId: number }[];
}): Promise<TCommonResponseType<string>> =>
  makeRequest<TCommonResponseType<string>>({
    url: `${basePath}/item`,
    method: EHttpMethods.PATCH,
    data,
  });

export const getFinalReport = (
  id: number,
): Promise<TCommonResponseType<TExpenseFinalReport>> =>
  makeRequest<TCommonResponseType<TExpenseFinalReport>>({
    url: `${basePath}/final-report/${id}`,
    method: EHttpMethods.GET,
  });

export const changeCategory = (data: {
  categoryId: number;
  expenseItemId: number;
  reportType: EReportType;
}): Promise<TCommonResponseType<TExpenseItem>> =>
  makeRequest<TCommonResponseType<TExpenseItem>>({
    url: `${basePath}/change-category`,
    method: EHttpMethods.PATCH,
    data,
  });
