import { EReportType } from 'utils/types.d';
import {
  object as yupObject,
  string as yupString,
  number as yupNumber,
  mixed as yupMixed,
} from 'yup';
import validationErrors from './validationErrors';

// Only letters and numbers and - _ . symbols
const excludeSymbolsRegExp = /^[a-z0-9_.-]*$/i;

export const mainDataStepScheme = yupObject().shape({
  month: yupString().required(),
  year: yupNumber().required(),
  lastName: yupString()
    .required()
    .matches(excludeSymbolsRegExp, validationErrors.invalidLastName),
  reportType: yupMixed().oneOf(Object.values(EReportType)),
  reportFile: yupMixed().required(),
});

const validation = {
  mainDataStep: mainDataStepScheme,
};

export default validation;
