import { Stepper as MaterialStepper, Step, StepLabel } from '@mui/material';

import { useCallback, useState } from 'react';
import { ArrowLeftIcon } from 'ui/assets/icons';
import UnstyledButton from 'ui/components/Buttons/UnstyledButton/UnstyledButton';
import WarningModal from 'ui/components/WarningModal/WarningModal';

import useAppContext from 'utils/hooks/useAppContext';

import MainData from './MainData';
import EditableData from './EditableData';
import FinalReport from './FinalReport';

const steps = [
  {
    title: 'Upload Your File',
    headerTitle: 'Main Data',
    component: MainData,
  },
  {
    title: 'Make changes data',
    headerTitle: 'Editable data',
    component: EditableData,
  },
  {
    title: 'Get final report',
    headerTitle: 'Final report',
    component: FinalReport,
  },
];

const Stepper = () => {
  const { setReportId, setHighlightedCellsIds } = useAppContext();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [isWarningOpen, setIsWarningOpen] = useState<boolean>(false);

  const currentStep = steps[activeStep];

  const handleNextStep = useCallback(() => {
    const maxIndex = steps.length - 1;

    setActiveStep((currentStep) =>
      maxIndex === currentStep ? currentStep : currentStep + 1,
    );
  }, []);

  const handlePreviousStep = useCallback(() => {
    setActiveStep((currentStep) => (currentStep ? currentStep - 1 : 0));
    setIsWarningOpen(false);
  }, []);

  const handleCloseWarningModal = useCallback(
    () => setIsWarningOpen(false),
    [],
  );

  const handleOpenWarningModal = () => setIsWarningOpen(true);

  const isEditableStep = activeStep === 1;

  const handleFinish = () => {
    setActiveStep(0);
    setReportId(null);
    setHighlightedCellsIds([]);
  };

  return (
    <div className="category-comparison-stepper-container">
      <MaterialStepper
        alternativeLabel
        activeStep={activeStep}
        className="category-comparison-stepper"
      >
        {steps.map(({ title }) => (
          <Step key={title}>
            <StepLabel>{title}</StepLabel>
          </Step>
        ))}
      </MaterialStepper>
      {currentStep && (
        <div className="category-comparison__data">
          {Boolean(activeStep) && (
            <UnstyledButton
              className="category-comparison__data-back"
              onClick={
                isEditableStep ? handleOpenWarningModal : handlePreviousStep
              }
            >
              <ArrowLeftIcon /> Back to previous step
            </UnstyledButton>
          )}
          <div className="category-comparison__data-title">
            {currentStep.headerTitle}
          </div>
          <currentStep.component onHandleNextStep={handleNextStep} onHandleFinish={handleFinish} />
        </div>
      )}
      <WarningModal
        open={isWarningOpen}
        onClose={handleCloseWarningModal}
        onBackToPrevious={handlePreviousStep}
      />
    </div>
  );
};

export default Stepper;
