import React, { memo } from 'react';
import { DownloadIcon } from 'ui/assets/icons/icons';
import UnstyledButton from 'ui/components/Buttons/UnstyledButton';

const Download: React.FC<{
  onClick: () => void;
}> = ({ onClick }) => (
  <UnstyledButton
    className="category-comparison__final-report-download"
    onClick={onClick}
    type="button"
  >
    Download Report <DownloadIcon />
  </UnstyledButton>
);

export default memo(Download);
