import { memo } from 'react';
import styled from '@emotion/styled';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

const Loader = () => (
  <StyledBox>
    <LinearProgress />
  </StyledBox>
);

export default memo(Loader);

const StyledBox = styled(Box)`
  width: 100%;
`;
