import * as ExcelJS from 'exceljs';

import { ETransactionType, TExpenseItem, TSubtotalsType } from './types.d';
import {
  FINAL_REPORT_COLUMNS,
  SUBTOTAL_CATEGORIES_COLUMNS,
  SUBTOTAL_ACCOUNTS_COLUMNS,
} from './constants';
import { formatNegativeAmount, isPositive, toTwoNumbersAfterDecimal } from './helpers';

const createSheet = (name: string) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet(name);

  const getColumn = (columnName: string) => sheet.getColumn(columnName);

  return { workbook, sheet, getColumn };
};

export const createReport = async (
  fileName: string,
  data: TExpenseItem[],
  hightlitedCellsIds: number[],
) => {
  const { workbook, sheet: reportSheet, getColumn } = createSheet('Report');
  reportSheet.columns = FINAL_REPORT_COLUMNS;

  data.forEach((item: TExpenseItem) => {
    const amountWithTwoNumbersAfterDecimal = toTwoNumbersAfterDecimal(
      item.amount,
    );
    const isPositiveAmount = item.transactionType === ETransactionType.CREDIT;

    reportSheet.addRow({
      id: item.id,
      date: item.date,
      description: item.description,
      originalDescription: item.description,
      amount: isPositiveAmount
        ? Number(amountWithTwoNumbersAfterDecimal)
        : Number(-amountWithTwoNumbersAfterDecimal),
      transactionType: item.transactionType,
      category: item.category?.name || 'Missing Data',
      drBudgetsCategory: item.drBudgetsCategory?.name || 'Missing Data',
      accountName: item.accountName,
      labels: item.labels,
      notes: item.notes,
    });
  });

  const hightlitedIndexes: string[] = [];
  const negativeAmountsIndexes: string[] = [];

  const idCol = getColumn('id');
  const amountCol = getColumn('amount');
  const categoryColumn = getColumn('category');
  const amountColLetter = amountCol.letter;
  const categoryColLetter = categoryColumn.letter;
  idCol.hidden = true;

  data.forEach((item, index) => {
    const isNegativeAmount = item.transactionType === ETransactionType.DEBIT;
    const isHightlited = hightlitedCellsIds.includes(item.id);
    // We need to add 2 because cell numbers start from 1 and index from 0 and the first cell is always the heading
    const cellNumber = index + 2;

    if (isHightlited) {
      hightlitedIndexes.push(`${categoryColLetter}${cellNumber}`);
    }

    if (isNegativeAmount) {
      negativeAmountsIndexes.push(`${amountColLetter}${cellNumber}`);
    }
  });

  hightlitedIndexes.forEach((item) => {
    reportSheet.getCell(item).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '32ffe600' },
    };
  });

  negativeAmountsIndexes.forEach((item) => {
    reportSheet.getCell(item).font = {
      color: { argb: 'ffcc272a' },
    };
  });

  addAlignStyles(reportSheet);

  writeFile(workbook, fileName);
};

export const createSubtotals = (
  accountNameData: TSubtotalsType,
  categoriesData: TSubtotalsType,
  fileName: string,
) => {
  const {
    workbook,
    sheet: categorySheet,
    getColumn,
  } = createSheet('subtotals by category');

  const accountSheet = workbook.addWorksheet('subtotals by account name');

  categorySheet.columns = SUBTOTAL_CATEGORIES_COLUMNS;
  accountSheet.columns = SUBTOTAL_ACCOUNTS_COLUMNS;

  addRowToSheet(categorySheet, 'drBudgetsCategory', categoriesData);
  addRowToSheet(accountSheet, 'accountName', accountNameData);

  const amountCol = getColumn('amount');
  const amountColLetter = amountCol.letter;

  const accountNegativeAmounts = getNegativeAmountsIndexesArray(
    accountNameData,
    amountColLetter,
  );
  const categoriesNegativeAmounts = getNegativeAmountsIndexesArray(
    categoriesData,
    amountColLetter,
  );

  addAlignStyles(categorySheet);
  addAlignStyles(accountSheet);

  addRedColorStyle(accountNegativeAmounts, accountSheet);
  addRedColorStyle(categoriesNegativeAmounts, categorySheet);

  writeFile(
    workbook,
    generateFileNameForSubtotalsReportFromOriginalReportName(fileName),
  );
};

const writeFile = (workbook: ExcelJS.Workbook, fileName: string) => {
  workbook.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = `${fileName}.xlsx`;
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
};

const addRowToSheet = (
  sheet: ExcelJS.Worksheet,
  rowName: string,
  data: TSubtotalsType,
) => {
  Object.keys(data).forEach((item) => {
    const amount = data[item as keyof typeof data];
    const isPositiveAmount = isPositive(amount.toString());

    sheet.addRow({
      [rowName]: item,
      amount: isPositiveAmount ? amount :  `(${formatNegativeAmount(amount.toString())})`,
    });
  });
};

const getNegativeAmountsIndexesArray = (
  data: TSubtotalsType,
  columnLetter: string,
) => {
  const result: string[] = [];

  Object.keys(data).forEach((item, index) => {
    const amount = data[item as keyof typeof data];
    const isPositiveAmount = isPositive(amount.toString());
    const cellIndex = index + 2;

    if (isPositiveAmount) return;


    result.push(`${columnLetter}${cellIndex}`);
  });

  return result;
};

const addAlignStyles = (sheet: ExcelJS.Worksheet) => {
  sheet.columns.forEach((column) => {
    column.eachCell!((cell) => {
      // eslint-disable-next-line no-param-reassign
      cell.alignment = {
        wrapText: true,
        vertical: 'middle',
        horizontal: 'left',
      };
    });
  });
};

const addRedColorStyle = (arr: string[], sheet: ExcelJS.Worksheet) => {
  arr.forEach((item) => {
    // eslint-disable-next-line no-param-reassign
    sheet.getCell(item).font = {
      color: { argb: 'ffcc272a' },
    };
  });
};

const generateFileNameForSubtotalsReportFromOriginalReportName = (
  name: string,
) => {
  const nameArr = name.split('-');
  const year = nameArr[0];
  const month = nameArr[1];
  const lastName = nameArr[nameArr.length - 1];

  return `${year}-${month}-S-${lastName}`;
};
