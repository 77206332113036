import React from 'react';
import ReactDOM from 'react-dom/client';

import GlobalStyles from 'ui/styles/GlobalStyles';
import EmotionProvider from 'ui/styles/theme';

import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <EmotionProvider>
      <GlobalStyles />
      <App />
    </EmotionProvider>
  </React.StrictMode>,
);
