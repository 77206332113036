import React, { memo, useCallback, useState } from 'react';

import {
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableBody,
  TableCell,
  SelectChangeEvent,
} from '@mui/material';

import useReport from 'utils/hooks/useReport';
import useAppContext from 'utils/hooks/useAppContext';
import { TExpenseItemCategory } from 'utils/types';
import useCategories from 'utils/hooks/useCategories';
import { capitalizeFirstLetter, toTwoNumbersAfterDecimal } from 'utils/helpers';

import NextButton from 'ui/components/Buttons/NextButton';
import UnstyledButton from 'ui/components/Buttons/UnstyledButton';
import { ArrowRightIcon } from 'ui/assets/icons';
import Loader from 'ui/components/Loader';

import api from 'api';

import CategorySelect from './components/CategorySelect';

const DR_BUDGETS_CATEGORY_COLUMN_NAME = 'Dr. Budget Category';
const DR_BUDGETS_DATA_KEY_NAME = 'drBudgetsCategory';
const CATEGORY = 'category';

const columns = [
  '#',
  'Date',
  'Description',
  'Original description',
  'Аmount',
  'Transaction type',
  capitalizeFirstLetter(CATEGORY),
  DR_BUDGETS_CATEGORY_COLUMN_NAME,
  'Account Name',
  'Labels',
  'Notes',
];

const dataKeys = [
  'date',
  'description',
  'originalDescription',
  'amount',
  'transactionType',
  CATEGORY,
  DR_BUDGETS_DATA_KEY_NAME,
  'accountName',
  'labels',
  'notes',
];

type TProps = {
  onHandleNextStep: () => void;
};

const EditableData: React.FC<TProps> = ({ onHandleNextStep }) => {
  const { highlightedCellsIds, setHighlightedCellsIds, reportId } =
    useAppContext();

  const { report, updatePeportItemCategory, isLoading, handleSortReport } =
    useReport(reportId!);

  const { categories, defaultCategoryId } = useCategories(report?.type);

  const [updatingCellInRowId, setUpdatingCellInRowId] = useState<number | null>(
    null,
  );

  const handleHighlightRow = (e: React.MouseEvent<HTMLTableCellElement>) => {
    e.stopPropagation();

    const isDoubleClick = e.detail === 2;
    if (!isDoubleClick) return;

    const eventTargetId = Number((e.target as HTMLTableCellElement).id);

    setHighlightedCellsIds((prev) => {
      const indexOfElement = prev.indexOf(eventTargetId);

      return indexOfElement === -1
        ? [...prev, eventTargetId]
        : [...prev.slice(0, indexOfElement), ...prev.slice(indexOfElement + 1)];
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (!report) return;

      const updatedExpenseItems = report.items.filter((item) => item.isUpdated);

      const formattedUpdatedData = updatedExpenseItems.map((item) => ({
        itemId: item.id,
        newCategoryId: item.drBudgetsCategoryId,
      }));

      await api.expense.updateReportItems({
        dataToUpdate: formattedUpdatedData,
      });

      onHandleNextStep();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  const handleUpdateCategory = useCallback(
    async (e: SelectChangeEvent<number>, rowId: number) => {
      setUpdatingCellInRowId(rowId);
      await updatePeportItemCategory(e, rowId);

      setHighlightedCellsIds((prev) =>
        prev.includes(rowId) ? prev : [...prev, rowId],
      );
      setUpdatingCellInRowId(null);
    },
    [setHighlightedCellsIds, updatePeportItemCategory],
  );

  if (isLoading) return <Loader />;

  return (
    <form onSubmit={handleSubmit}>
      <TableContainer
        sx={{ maxHeight: 440 }}
        className="category-comparison__editable-data-table-container"
      >
        <Table
          stickyHeader
          aria-label="sticky table"
          className="category-comparison__editable-data-table"
        >
          <TableHead className="category-comparison__editable-data-table-head">
            <TableRow>
              {columns.map((column) => {
                const shortColumnName = column.split(' ')[0].toLowerCase();
                const isDrBudgetsColumnName =
                  column === DR_BUDGETS_CATEGORY_COLUMN_NAME;
                return (
                  <TableCell
                    className={`category-comparison__editable-data-table-${shortColumnName}`}
                    key={column}
                  >
                    {isDrBudgetsColumnName ? (
                      <>
                        <span>{column}</span>
                        <UnstyledButton
                          type="button"
                          onClick={() => handleSortReport(report)}
                          className="category-comparison__editable-data-table-sort-button"
                        >
                          А↓
                        </UnstyledButton>
                      </>
                    ) : (
                      column
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {report?.items.map((row, index) => (
              <TableRow
                key={row.id}
                className="category-comparison__editable-data-table-row"
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                {dataKeys.map((key, index) => {
                  const isCategoryKey = key === CATEGORY;
                  const isDrBudgetsCategoryKey =
                    key === DR_BUDGETS_DATA_KEY_NAME;
                  const isAmountColumn = key === 'amount';
                  if (isCategoryKey) {
                    return (
                      <TableCell
                        id={row.id.toString()}
                        key={row.id}
                        className={
                          highlightedCellsIds.includes(row.id)
                            ? 'category-comparison__editable-data-table__category--highlighted'
                            : 'category-comparison__editable-data-table__category'
                        }
                        onClick={handleHighlightRow}
                      >
                        <CategorySelect
                          currentRowId={row.id}
                          onChange={handleUpdateCategory}
                          categoryId={row.categoryId}
                          categories={categories}
                          defaultCategoryId={defaultCategoryId}
                          isDisabled={updatingCellInRowId === row.id}
                        />
                      </TableCell>
                    );
                  }

                  if (isAmountColumn) {
                    const amount = row[key as keyof typeof row] as string;
                    return (
                      <TableCell key={index}>
                        {toTwoNumbersAfterDecimal(+amount)}
                      </TableCell>
                    );
                  }

                  return (
                    <TableCell key={index}>
                      {isDrBudgetsCategoryKey
                        ? (row[key as keyof typeof row] as TExpenseItemCategory)
                            .name
                        : (row[key as keyof typeof row] as string)}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="category-comparison__editable-data-submit-wrapper">
        <NextButton type="submit">
          Submit <ArrowRightIcon />
        </NextButton>
      </div>
    </form>
  );
};

export default memo(EditableData);
