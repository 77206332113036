import { memo } from 'react';

import Header from 'ui/components/Header';
import CategoryComparison from 'pages/CategoryComparison';

import 'App.css';

const App = () => (
  <>
    <Header />

    <CategoryComparison />
  </>
);

export default memo(App);
