import { memo, FC } from 'react';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import StyledWarningModal from './WarningModal.styles';

import UnstyledButton from '../Buttons/UnstyledButton';
import NextButton from '../Buttons/NextButton';

type TProps = {
  onClose: () => void;
  onBackToPrevious: () => void;
  open: boolean;
};

const WarningModal: FC<TProps> = ({ open, onClose, onBackToPrevious }) => (
  <StyledWarningModal open={open} onClose={onClose}>
    <DialogTitle className="title">
      Are you sure you want to go back to the previous step?
    </DialogTitle>
    <DialogContent className="content">
      <DialogContentText>
        If you go back, the changes in the table data won't be saved.
      </DialogContentText>
    </DialogContent>
    <DialogActions className="buttons">
      <UnstyledButton className="buttons-agree" onClick={onBackToPrevious}>
        Yes, back to previous step
      </UnstyledButton>
      <NextButton className="buttons-disagree" onClick={onClose} autoFocus>
        No, continue here
      </NextButton>
    </DialogActions>
  </StyledWarningModal>
);

export default memo(WarningModal);
