import { memo, useState } from 'react';

import Header from './components/Header';
import Stepper from './components/Stepper';

import StyledCategoryComparison from './CategoryComparison.styles';
import { CagegoryCompressionProvider } from '../../context/context';

const CategoryComparison = () => {
  const [reportId, setReportId] = useState<number | null>(null);
  const [highlightedCellsIds, setHighlightedCellsIds] = useState<number[]>([]);

  return (
    <CagegoryCompressionProvider
      value={{
        reportId,
        setReportId,
        highlightedCellsIds,
        setHighlightedCellsIds,
      }}
    >
      <StyledCategoryComparison className="category-comparison">
        <Header />
        <Stepper />
      </StyledCategoryComparison>
    </CagegoryCompressionProvider>
  );
};

export default memo(CategoryComparison);
