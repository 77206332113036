import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Dialog from '@mui/material/Dialog';

const StyledWarningModal = styled(Dialog)(
  ({ theme }) => css`
    & .MuiPaper-root {
      padding: 48px 48px 40px;
    }

    .title,
    .content {
      padding: 0;
      text-align: center;
      line-height: ${theme.font.lineHeight.main};
    }

    .title {
      font-size: 18px;
      color: ${theme.colors.mainText};
      margin-bottom: 10px;
    }

    .content {
      color: ${theme.colors.secondaryText};
      margin-bottom: 40px;
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      padding: 0;

      &-agree,
      &-disagree {
        width: 100%;
      }

      &-agree {
        max-width: 185px;
        color: ${theme.colors.mainBlue};
      }

      &-disagree {
        max-width: 196px;
        text-transform: none;
        margin-left: 0;
      }
    }
  `,
);

export default StyledWarningModal;
