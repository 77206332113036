import {
  EHttpMethods,
  EReportType,
  TCategory,
  TCommonResponseType,
} from 'utils/types.d';
import makeRequest from './makeRequest';

const basePath = '/category';
const drBudgetsPath = '/budgets';

export const getDrBudgetsCategories = (): Promise<
  TCommonResponseType<TCategory[]>
> =>
  makeRequest<TCommonResponseType<TCategory[]>>({
    url: `${basePath}${drBudgetsPath}`,
    method: EHttpMethods.GET,
  });

export const getDrBudgetsCategoriesByReportType = (
  type: EReportType,
): Promise<TCommonResponseType<TCategory[]>> =>
  makeRequest<TCommonResponseType<TCategory[]>>({
    url: `${basePath}${drBudgetsPath}-by-type`,
    method: EHttpMethods.GET,
    params: {
      reportType: type,
    },
  });

  export const getCategoriesByReportType = (
    type: EReportType,
  ): Promise<TCommonResponseType<TCategory[]>> =>
    makeRequest<TCommonResponseType<TCategory[]>>({
      url: `${basePath}/by-type`,
      method: EHttpMethods.GET,
      params: {
        reportType: type,
      },
    });
