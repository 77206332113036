import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { InputBase, MenuItem } from '@mui/material';

export const StyledMenuItem = styled(MenuItem)`
  font-style: normal;
  ${({ theme }) => css`
    font-family: ${theme.font.family.main};
    font-weight: ${theme.font.weight.sm};
    font-size: ${theme.font.size.sm};
    line-height: ${theme.font.lineHeight.main};
    color: ${theme.colors.secondaryText};
  `}

  padding: 13px 24px;
`;

export const StyledCustomInput = styled(InputBase)(
  ({ theme }) => css`
    svg {
      top: 50%;
      transform: translate(-50%);
    }

    & .MuiInputBase-input {
      border-radius: 4;
      position: relative;
      background: inherit;
      border: none;
      font-size: ${theme.font.size.sm};
      padding: 0;
      margin: 0;
      width: 165px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: ${theme.colors.mainText};
      transition: ${theme.transition};

      &:focus {
        border-radius: 4;
        border: none;
        box-shadow: none;
        background: inherit;
      }
    }
  `,
);

const StyledCategoryComparison = styled.main(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;

    max-width: ${theme.widths.main.section}px;
    width: 100%;
    margin-top: 119px;

    .category-comparison {
      &-header {
        display: flex;
        justify-content: center;
        width: 100%;
        font-style: normal;
        line-height: 120%;
        text-align: center;
        letter-spacing: ${theme.font.letterSpacing};
        margin-bottom: 40px;

        font-weight: ${theme.font.weight.sLg};
        font-size: ${theme.font.size.lg};
        color: ${theme.colors.mainText};
      }

      &-stepper-container {
        width: 100%;
      }

      &-stepper {
        margin-bottom: 35px;

        fill: white;
      }

      &__data {
        display: flex;
        flex-direction: column;
        color: ${theme.colors.mainText};
        margin-bottom: 20px;
        padding: 20px;

        &-title {
          font-style: normal;
          font-weight: ${theme.font.weight.sLg};
          font-size: ${theme.font.size.sLg};
          line-height: 120%;
          letter-spacing: ${theme.font.letterSpacing};
          margin-bottom: 20px;
          text-transform: uppercase;
        }

        &-back {
          display: flex;
          align-items: center;
          color: ${theme.colors.mainBlue};
          margin-bottom: 40px;

          svg {
            margin-right: 8px;
            path {
              stroke: ${theme.colors.mainBlue};
            }
          }
        }
      }

      &__main-data {
        &-next-button {
          align-self: flex-end;
        }

        &-form {
          padding: 40px;
          border: 1px solid ${theme.colors.mainBorder};
          box-shadow: -10px 10px 30px 4px rgba(19, 36, 97, 0.04);
          border-radius: 4px;
          margin-bottom: 40px;

          &-radio-group {
            label:first-of-type {
              margin-right: 40px;
            }
          }

          &-input {
            color: #90a4ae;
            padding-left: 6px;
            ${css`
              font-weight: ${theme.font.weight.xSm};
              font-size: ${theme.font.size.sm};
              font-family: ${theme.font.family.main};
            `}
          }

          &-select {
            max-width: 194px;
            width: 100%;
            display: flex;
            align-items: center;

            font-style: normal;

            font-family: ${theme.font.family.main};
            font-weight: ${theme.font.weight.xSm};
            font-size: ${theme.font.size.sm};
            line-height: ${theme.font.lineHeight.main};

            svg {
              top: 50%;
              right: 10%;
              transform: translateY(-50%);
            }

            .MuiOutlinedInput-input {
              padding-left: 20px;

              color: ${theme.colors.secondaryText};
            }
          }

          &-textfield {
            width: 401px;

            .MuiInputBase-input {
              padding: 14px 20px;

              font-style: normal;

              font-family: ${theme.font.family.main};
              font-weight: ${theme.font.weight.sm};
              font-size: ${theme.font.size.sm};
              color: ${theme.colors.secondaryText};
              line-height: ${theme.font.lineHeight.main};

              &::placeholder {
                color: #90a4ae;
              }
            }
          }

          &-row {
            display: flex;
            margin-bottom: 40px;

            &-name {
              width: 162px;
              margin-right: 60px;
            }
          }

          &-dropzone {
            max-width: 77%;
            width: 100%;
            height: 78px;

            &-bigger-height {
              height: 122px;
            }

            &-with-file {
              display: flex;
              justify-content: center;
              flex-direction: column;
              align-items: center;
            }

            &-file {
              font-style: normal;

              font-family: ${theme.font.family.main};
              font-weight: ${theme.font.weight.sm};
              line-height: ${theme.font.lineHeight.main};

              &-name {
                margin-bottom: 4px;
                font-size: ${theme.font.size.sm};
                color: ${theme.colors.secondaryText};
              }

              &-size {
                color: #90a4ae;
                font-size: ${theme.font.size.xSm};
                margin-bottom: 21px;
              }
            }

            &-csv-icon {
              margin-bottom: 21px;
            }

            &-csv-icon--green {
              margin-bottom: 21px;

              path {
                fill: #4fb979;
              }
              path {
                fill: #4fb979;
              }

              path:nth-of-type(3),
              path:nth-of-type(4),
              path:nth-of-type(5),
              path:nth-of-type(6) {
                fill: #ffffff;
              }
            }

            &-upload-icon {
              margin-left: 4px;
            }

            &-text {
              display: flex;
              align-items: center;

              &-blue {
                color: ${theme.colors.mainBlue};
                margin-left: 8px;
              }
            }

            &-delete-button {
              display: flex;
              font-style: normal;
              align-items: center;

              font-family: ${theme.font.family};
              font-weight: ${theme.font.weight.sm};
              font-size: ${theme.font.size.sm};
              line-height: ${theme.font.lineHeight.main};
              color: ${theme.colors.errorText};
            }

            &-error {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              color: ${theme.colors.errorText};
              margin-bottom: 20px;

              svg {
                margin-bottom: 21px;
              }
            }
          }

          &-select-month {
            margin-right: 13px;
          }

          .MuiTypography-root {
            color: ${theme.colors.secondaryText};
            font-size: ${theme.font.size.sm};
          }

          .MuiFormHelperText-root {
            margin-left: 0;
            font-style: normal;

            font-family: ${theme.font.family.main};
            font-weight: ${theme.font.weight.sm};
            font-size: ${theme.font.size.xSm};
            line-height: ${theme.font.lineHeight.main};
          }
        }
      }

      &__editable-data {
        &-submit-wrapper {
          display: flex;
          justify-content: flex-end;
          margin-bottom: 40px;
        }

        &-table {
          border: 1px solid ${theme.colors.mainBorder};
          border-radius: 4px;

          &-sort-button {
            margin-left: 5px;
            color: ${theme.colors.mainBlue};
            font-weight: ${theme.font.weight.lg};
          }

          &-menu {
            width: 260px;
          }

          &__category,
          &__category--highlighted {
            max-width: 200px;
            cursor: pointer;

            svg {
              margin-left: 4px;
            }
          }

          &__category {
            &-select {
              max-width: 160px;
            }
          }

          &__category--highlighted {
            background: rgba(255, 230, 0, 0.2);
          }

          &-container {
            margin-bottom: 40px;
            border-bottom: 1px solid ${theme.colors.mainBorder};
          }

          th:first-of-type {
            padding-left: 20px;
            padding-right: 9px;
          }

          thead th:first-of-type {
            color: ${theme.colors.mainBlue};
          }

          thead th {
            background: #f8f8fe;
            border: none;
            padding: 14.5px 4.4px;

            font-weight: ${theme.font.weight.sm};
            font-size: ${theme.font.size.sm};
            line-height: ${theme.font.lineHeight.main};
            color: ${theme.colors.secondaryText};

            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          td {
            padding: 10px 4.4px;

            line-height: 130%;
            color: ${theme.colors.mainText};
          }

          &-row {
            background: #ffffff;
            transition: all 0.1s;
          }

          /* headers for columns: desc, or.desc */
          &-row :nth-of-type(2),
          &-row :nth-of-type(3),
          &-description,
          &-original {
            width: 138px;
            word-break: break-all;
          }

          &-row :nth-of-type(4),
          &-amount {
            width: 80px;
          }

          &-row :nth-of-type(4)::before {
            content: '$';
          }

          /*headers for columns: labels, notes */
          &-row :nth-of-type(9),
          &-row :nth-of-type(10) {
            width: 60px;
            text-overflow: ellipsis;
            overflow: hidden;
            word-wrap: nowrap;
          }
        }
      }

      &__final-report {
        &-subtitle {
          font-weight: ${theme.font.weight.lg};
          font-size: ${theme.font.size.xMd};
          line-height: 120%;

          letter-spacing: ${theme.font.letterSpacing};
          text-transform: uppercase;

          color: ${theme.colors.mainText};
          margin-bottom: 20px;
        }

        &-helper-text {
          font-weight: ${theme.font.weight.sm};
          font-size: ${theme.font.size.sm};
          margin-bottom: 20px;
        }

        &-inner-container {
          border: 1px solid ${theme.colors.mainBorder};
          border-radius: 4px;
          padding: 20px;
          margin-bottom: 20px;
        }

        &-amounts {
          display: flex;
          flex-direction: column;

          &-item {
            flex: 0 1 calc(50% - 62px);
          }

          &-info {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-around;

            /* TODO ASK ABOUT THIS */
            height: 250px;
            overflow: scroll;

            font-weight: ${theme.font.weight.sm};
            font-size: ${theme.font.size.sm};
            line-height: ${theme.font.lineHeight.main};
            color: ${theme.colors.secondaryText};

            &-column {
              display: flex;
              flex-direction: row;
              width: 100%;
              justify-content: space-between;

              &-title,
              &-item {
                margin-bottom: 14px;
              }

              &-row {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: ${theme.colors.mainText};
                margin-bottom: 20px;
              }
            }

            &-column-right {
              text-align: right;
            }
          }
        }

        &-amounts-wrapper {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          width: 100%;
        }

        &-container {
          padding: 40px;
          border: 1px solid ${theme.colors.mainBorder};
          box-shadow: -10px 10px 30px 4px rgba(19, 36, 97, 0.04);
          border-radius: 4px;
          margin-bottom: 40px;
        }

        &-download {
          display: flex;
          align-items: center;
          color: ${theme.colors.mainBlue};

          svg {
            margin-left: 4px;
          }
        }

        &-finish {
          display: flex;
          justify-content: flex-end;
        }
      }
    }

    .MuiTableContainer-root {
      border-radius: 4px;
    }

    .MuiTableCell-root {
      border-bottom: 1px solid ${theme.colors.mainBorder};
    }

    .select {
      max-width: 170px;
    }
  `,
);

export default StyledCategoryComparison;
