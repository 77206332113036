import styled from '@emotion/styled';

import BaseButton from '../BaseButton/BaseButton';

const NextButton = styled(BaseButton)`
  svg {
    margin-left: 6px;
  }
`;

export default NextButton;
