import { EReportType, TExpenseReport, TFormValues } from './types.d';

export const generateArrayOfYears = () => {
  const minYear = getFullYearFromCurrentDate();
  const maxYear = minYear + 1;

  const years = [];

  for (let i = minYear; i <= maxYear; i += 1) {
    years.push(i);
  }

  return years;
};

export const getNumberOfTheMonthInTheYear = (month: string) => {
  // this is fix for mozila browser don't delete this line
  const year = getFullYearFromCurrentDate();
  const time = new Date(`${month} 1 ${year}`);
  return time.getMonth() + 1;
};

export const generateFileName = (data: TFormValues) => {
  const { year, month, reportType, lastName } = data;
  const reportTypeAbbreviation =
    reportType === EReportType.PERSONAL ? 'P' : 'B';

  const fileName = `${year}-${getNumberOfTheMonthInTheYear(
    month,
  )}-transactions-${reportTypeAbbreviation}-${lastName}`;

  return fileName;
};

export const getFullYearFromCurrentDate = () => {
  const date = new Date();
  const currentYear = date.getFullYear();
  return currentYear;
};

export const getNameForSorting = (name: string) => {
  // since the name of each category name looks like (H01) Something
  // we need to sort by value in () 
  const arr = name.split('(');

  const firstWord = arr.length > 1 ? arr[1].trim() : arr[0].trim();
  return firstWord;
};

export const sortReportItemsByDrBudgetsCatagoryName = (
  data: TExpenseReport,
) => {
  if (!data) return null;

  const clonedObject: TExpenseReport = structuredClone(data);

  const changedData = {
    ...clonedObject,
    items: clonedObject.items.sort((a, b) => {
      const nameA = getNameForSorting(a.drBudgetsCategory.name);
      const nameB = getNameForSorting(b.drBudgetsCategory.name);

      return sortStringAlphabetically(nameA, nameB);
    }),
  };

  return changedData;
};

export const isAlphabetical = (item: string) => item.localeCompare('a') >= 0;

export const sortStringAlphabetically = (itemA: string, itemB: string) =>
  Number(isAlphabetical(itemB)) - Number(isAlphabetical(itemA)) ||
  itemA.localeCompare(itemB);

export const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

// all numbers from the back come with two digits after the dot
export const isPositive = (value: string) =>
  value === '0.00' ? true : Math.sign(Number(value)) === 1;

export const formatNegativeAmount = (amount: string) =>
  amount.toString().slice(1);

export const addDollarSignToTransactionsAmout = (amount: string) => {
  if (!amount) return null;

  if (isPositive(amount)) return `$${amount}`;

  const amountWithOutMinusSign = formatNegativeAmount(amount);

  return `($${amountWithOutMinusSign})`;
};

export const toTwoNumbersAfterDecimal = (value: number) =>
  Number.parseFloat(value.toString()).toFixed(2);
