export const envTypes = {
  dev: 'development',
  prod: 'production',
  stage: 'staging',
};

const envType =
  process.env.REACT_APP_ENV || process.env.NODE_ENV || envTypes.dev;

export const isDev = envType === envTypes.dev;

const config = {
  apiUrl: 'http://localhost:5001/api/v2',
  baseUrl: 'http://localhost:3000',
  envType,
};

switch (envType) {
  case envTypes.prod:
    config.apiUrl = 'https://calculator.drbudgets.com/api/v2';
    config.baseUrl = '';
    break;

  case envTypes.stage:
    config.apiUrl = 'https://dr-budgets.fusion-tech.pro/api/v2';
    config.baseUrl = '';
    break;

  default:
}

export default config;
