import { createContext, Dispatch, SetStateAction } from 'react';

type TAppContex = {
  reportId: number | null;
  setReportId: Dispatch<SetStateAction<number | null>>;
  highlightedCellsIds: number[];
  setHighlightedCellsIds: Dispatch<SetStateAction<number[]>>;
};

export const AppContex = createContext<TAppContex | null>(null);

export const CagegoryCompressionProvider = AppContex.Provider;
