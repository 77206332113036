import { css, Global, useTheme } from '@emotion/react';

import { ThemeType } from './theme';

const getGlobalStyles = (theme: ThemeType) => css`
  * {
    padding: 0;
    margin: 0;
    color: inherit;
    outline: none;
    list-style: none;
    font-family: inherit;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  html,
  body,
  #root {
    min-height: 100%;
  }

  html,
  body {
    display: flex;
  }

  body,
  #root {
    width: 100%;
  }

  #root {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    transition: ${theme.transition};
  }

  html {
    font-family: ${theme.font.family.main};
    font-size: ${theme.font.size.globalValue};
    color: ${theme.colors.mainText};
    font-weight: ${theme.font.weight.md};
    background-color: ${theme.colors.pageBackground};
  }

  a {
    text-decoration: none;
  }

  input {
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus {
      box-shadow: 0 0 0 30px ${theme.colors.pageBackground} inset;
      -webkit-text-fill-color: ${theme.colors.mainText};
    }
  }
`;

const GlobalStyles = () => {
  const theme = useTheme();

  return <Global styles={getGlobalStyles(theme)} />;
};

export default GlobalStyles;
