import { memo } from 'react';
import { UploadIcon } from 'ui/assets/icons/icons';

const HelperText = () => (
    <p className="category-comparison__main-data-form-dropzone-text">
      Drag and drop .CSV or
      <span className="category-comparison__main-data-form-dropzone-text-blue">
        Choose File
      </span>
      <UploadIcon className="category-comparison__main-data-form-dropzone-upload-icon" />
    </p>
  );

export default memo(HelperText);
