import React, { useCallback, useMemo } from 'react';
import { FormikErrors } from 'formik';
import { useDropzone } from 'react-dropzone';

import { CsvIcon, ErrorIcon, TrashIcon } from 'ui/assets/icons';
import UnstyledButton from 'ui/components/Buttons/UnstyledButton';
import { REQUIRED_COLUMNS_IN_FILE } from 'utils/constants';

import HelperText from './HelperText';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyItems: 'center',
  alignItems: 'center',
  padding: '20px 40px',
  borderWidth: 2,
  borderRadius: 4,
  borderColor: '#F1F1F1',
  borderStyle: 'dashed',
  backgroundColor: '#ffffff',
  color: '#50535C',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  height: '100%',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#cc272a',
};

type TProps = {
  reportFile: File | null;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) =>
    | Promise<void>
    | Promise<
        FormikErrors<{
          month: string;
          year: string;
          lastName: string;
          reportType: string;
          file: null;
        }>
      >;
  isFileValid: boolean;
};

const StyledDropzone: React.FC<TProps> = ({
  setFieldValue,
  reportFile,
  isFileValid,
}) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const currentFile = acceptedFiles[0];

      const validateFile = (file: File) => {
        const fileReader = new FileReader();
        fileReader.readAsText(file, 'UTF-8');
        fileReader.onload = (e) => {
          if (!e.target || !e.target.result) {
            return setFieldValue('isFileValid', false);
          }

          const content = e.target.result as string;

          const isValid = REQUIRED_COLUMNS_IN_FILE.every(
            (columnName) =>
              content.toLowerCase().indexOf(columnName.toLowerCase()) !== -1,
          );

          return setFieldValue('isFileValid', isValid);
        };
      };

      validateFile(currentFile);
      setFieldValue('reportFile', currentFile);
    },
    [setFieldValue],
  );

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({
    accept: { 'text/csv': [] },
    maxFiles: 1,
    onDrop,
  });

  const isError = isDragReject || !isFileValid;

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isError ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isError],
  );

  const handleDeleteFile = () => {
    setFieldValue('reportFile', null);
  };

  const fileSizeInMb = (acceptedFiles[0]?.size / (1024 * 1024)).toFixed(2);

  if (reportFile && isFileValid) {
    return (
      <div className="category-comparison__main-data-form-dropzone">
        <div className="category-comparison__main-data-form-dropzone-with-file">
          <CsvIcon className="category-comparison__main-data-form-dropzone-csv-icon--green" />
          <p className="category-comparison__main-data-form-dropzone-file-name">
            {acceptedFiles[0].name}
          </p>
          <p className="category-comparison__main-data-form-dropzone-file-size">
            {fileSizeInMb} MB
          </p>
          <UnstyledButton
            className="category-comparison__main-data-form-dropzone-delete-button"
            onClick={handleDeleteFile}
          >
            Delete <TrashIcon />
          </UnstyledButton>
        </div>
      </div>
    );
  }

  return (
    <div
      className={
        isError
          ? 'category-comparison__main-data-form-dropzone category-comparison__main-data-form-dropzone-bigger-height'
          : 'category-comparison__main-data-form-dropzone'
      }
    >
      {/* @ts-ignore */}
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {isError ? (
          <>
            <div className="category-comparison__main-data-form-dropzone-error">
              <ErrorIcon />
              The file format is incorrect
            </div>
            <HelperText />
          </>
        ) : (
          <>
            <CsvIcon className="category-comparison__main-data-form-dropzone-csv-icon" />

            <HelperText />
          </>
        )}
      </div>
    </div>
  );
};

export default StyledDropzone;
