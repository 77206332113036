// eslint-disable-next-line import/prefer-default-export
export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const FINAL_REPORT_COLUMNS = [
  {
    header: 'Id',
    key: 'id',
  },
  {
    header: 'Date',
    key: 'date',
    width: 8,
  },
  {
    header: 'Description',
    key: 'description',
    width: 60,
  },
  {
    header: 'Original description',
    key: 'originalDescription',
    width: 60,
  },
  {
    header: 'Amount',
    key: 'amount',
    width: 15,
  },
  {
    header: 'Transaction Type',
    key: 'transactionType',
    width: 15,
  },
  {
    header: 'Category',
    key: 'category',
    width: 20,
  },
  {
    header: 'Dr.Budgets Category',
    key: 'drBudgetsCategory',
    width: 18,
  },
  {
    header: 'Account Name',
    key: 'accountName',
    width: 20,
  },
  {
    header: 'Labels',
    key: 'labels',
    width: 7,
  },
  {
    header: 'Notes',
    key: 'notes',
    width: 15,
  },
];

export const SUBTOTAL_CATEGORIES_COLUMNS = [
  {
    header: 'Dr.Budget Category',
    key: 'drBudgetsCategory',
    width: 40,
  },
  {
    header: 'Total amount',
    key: 'amount',
    width: 15,
  },
];
export const SUBTOTAL_ACCOUNTS_COLUMNS = [
  {
    header: 'Account Names',
    key: 'accountName',
    width: 40,
  },
  {
    header: 'Total amount',
    key: 'amount',
    width: 15,
  },
];


export const REQUIRED_COLUMNS_IN_FILE = [
  'Date',
  'Description',
  'Original Description',
  'Amount',
  'Transaction Type',
  'Category',
  'Account Name',
  'Labels',
  'Notes',
];