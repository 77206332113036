import React, { memo } from 'react';
import { addDollarSignToTransactionsAmout } from 'utils/helpers';

import { TSubtotalsType } from 'utils/types';

type TProps = {
  title: string;
  columnTitle: string;
  values: TSubtotalsType;
};

const SubtotalItem: React.FC<TProps> = ({ title, columnTitle, values }) => {
  const dataKeys = Object.keys(values);

  return (
    <div className="category-comparison__final-report-inner-container category-comparison__final-report-amounts-by-category category-comparison__final-report-amounts-item">
      <div className="category-comparison__final-report-subtitle">{title}</div>

      <div className="category-comparison__final-report-amounts-info ">
        <div className="category-comparison__final-report-amounts-info-column">
          <div className="category-comparison__final-report-amounts-info-column-title">
            {columnTitle}
          </div>
          <div className="category-comparison__final-report-amounts-info-column-title">
            Total amount
          </div>
        </div>
        {dataKeys.map((key, index) => {
          const value = values[key].toString();
          const amount = addDollarSignToTransactionsAmout(value) ?? '$0';

          return (
            <div
              key={index}
              className="category-comparison__final-report-amounts-info-column-row"
            >
              <div>{key}</div>
              <div>{amount}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default memo(SubtotalItem);
