import styled from '@emotion/styled';

const StyledHeader = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.mainBorder}`};
  height: ${({ theme }) => theme.header.minHeight};

  img {
    width: 54px;
    height: 54px;
  }

  .header-wrapper {
    max-width: ${({ theme }) => theme.widths.main.section}px;
    width: 100%;
    padding: 0 20px;
  }
`;

export default StyledHeader;
