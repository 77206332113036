import { memo } from 'react';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { TCategory } from 'utils/types';
import { ChevronDownIcon } from 'ui/assets/icons';

import { StyledCustomInput } from 'pages/CategoryComparison/CategoryComparison.styles';
import CellLoader from 'ui/components/CellLoader';

type TProps = {
  categoryId: number;
  onChange: (
    e: SelectChangeEvent<number>,
    rowId: number,
  ) => Promise<void> | null;
  categories: TCategory[] | null;
  currentRowId: number;
  defaultCategoryId: number | null;
  isDisabled: boolean;
};

const CategorySelect: React.FC<TProps> = (props) => {
  const { categoryId, onChange, categories, currentRowId, defaultCategoryId, isDisabled } =
    props;

  if (!defaultCategoryId || !categories) return <CellLoader />;

  const categoriesIds = categories.map((category) => category.id);
  const value = categoriesIds.includes(categoryId)
    ? categoryId
    : defaultCategoryId;

  return (
    <FormControl sx={{ m: 1 }} variant="standard">
      <Select
        value={value}
        onChange={(e) => onChange(e, currentRowId)}
        input={<StyledCustomInput />}
        IconComponent={ChevronDownIcon}
        defaultValue={defaultCategoryId}
        className="category-comparison__editable-data-table__category-select"
        disabled={isDisabled}
      >
        {categories.map((category) => (
          <MenuItem key={category.id} value={category.id}>
            {category.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default memo(CategorySelect);
