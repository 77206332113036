import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Button } from '@mui/material';

const BaseButton = styled(Button)(
  ({ theme }) => css`
    background: ${theme.colors.mainBlue};
    border-radius: 4px;

    font-family: ${theme.font.family.main};
    font-style: normal;
    font-weight: ${theme.font.weight.sm};
    font-size: 16px;
    line-height: ${theme.font.lineHeight.main};
    padding: 9.5px 35px;
    color: #ffffff;

    .arrow-right {
      margin-left: 6px;
    }

    .arrow-left {
      margin-right: 6px;
    }

    :hover {
      background: #319fd6;
    }

    :focused {
      background: #044566;
    }

    :disabled {
      background: #ced2d3;
      color: #ffffff;
    }
  `,
);

export default BaseButton;
