export type ChildrenType = JSX.Element | JSX.Element[] | string;

export enum EReportType {
  PERSONAL = 'personal',
  BUSINESS = 'business',
}

export type TFormValues = {
  month: string;
  year: string;
  lastName: string;
  reportType: EReportType;
  reportFile: File | null;
};

export type TCreateReport = {
  reportFile: File | null;
  reportType: EReportType;
  newFileName: string;
};

export enum EHttpMethods {
  POST = 'POST',
  GET = 'GET',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export type TExpenseItemCategory = {
  id: number;
  name: string;
};

export enum ETransactionType {
  DEBIT = 'debit',
  CREDIT = 'credit',
}

export type TExpenseItem = {
  isUpdated?: boolean;
  accountName: string;
  amount: number;
  category: TExpenseItemCategory;
  categoryId: number;
  date: string;
  description: string;
  drBudgetsCategory: TExpenseItemCategory;
  drBudgetsCategoryId: number;
  id: number;
  labels: string | null;
  notes: string | null;
  originalDescription: string;
  reportId: number;
  transactionType: ETransactionType;
};

export type TExpenseReport = {
  id: number;
  type: EReportType;
  items: TExpenseItem[];
  name: string;
} & TTimestamps;

export type TExpenseFinalReport = {
  report: TExpenseReport;
  subtotalsByAccountName: Record<string, number>;
  subtotalsOfDrBudgetsCategories: Record<string, number>;
};

export type TCategory = {
  id: number;
  name: string;
  isDrBudgetsCategory: boolean;
  drBudgetsPersonalId: number | null;
  drBudgetsBusinessId: number | null;
} & TTimestamps;

type TTimestamps = {
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};

export type TCommonResponseType<T> = {
  data: T;
};

export type TSubtotalsType = Record<string, number>;

export enum EDefaultCategoriesByExpenseReportType {
  PERSONAL = '(H03) Uncategorized',
  BUSINESS = 'Uncategorized',
}
