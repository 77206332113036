import { useEffect, useState } from 'react';

import { EReportType, TCategory } from 'utils/types.d';
import { sortStringAlphabetically } from 'utils/helpers';

import api from 'api';

const useCategories = (type?: EReportType) => {
  const [categories, setCategories] = useState<TCategory[] | null>(null);
  const [defaultCategoryId, setDefaultCategoryId] = useState<number | null>(
    null,
  );

  useEffect(() => {
    if (!type) {
      return;
    }

    const fetchCategories = async () => {
      try {
        const { data } = await api.category.getCategoriesByReportType(type);

        const defaultCategory = data.find(
          (item) => item.name === 'Uncategorized',
        );

        const clonedData: TCategory[] = structuredClone(data);
        const sortedCategories = clonedData.sort((a, b) =>
          sortStringAlphabetically(a.name, b.name),
        );

        setCategories(sortedCategories);
        setDefaultCategoryId(defaultCategory!.id);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    };

    fetchCategories();
  }, [type]);

  return { categories, defaultCategoryId };
};

export default useCategories;
