import { Fragment, memo } from 'react';
import { useFormik } from 'formik';
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';

import {
  ArrowRightIcon,
  ChevronDownIcon,
  RadioCheckedIcon,
  RadioIcon,
} from 'ui/assets/icons';
import NextButton from 'ui/components/Buttons/NextButton';

import { EReportType } from 'utils/types.d';
import { generateArrayOfYears, generateFileName } from 'utils/helpers';
import { MONTHS } from 'utils/constants';
import { mainDataStepScheme } from 'utils/validation';
import useAppContext from 'utils/hooks/useAppContext';

import { StyledMenuItem } from 'pages/CategoryComparison/CategoryComparison.styles';

import api from 'api';

import StyledDropzone from './components/StyledDropzone';

type TFormValues = {
  month: string;
  year: string;
  lastName: string;
  reportType: EReportType;
  reportFile: File | null;
  isFileValid: boolean;
};

const years = generateArrayOfYears();

type TProps = {
  onHandleNextStep: () => void;
};

const MainData: React.FC<TProps> = ({ onHandleNextStep }) => {
  const { setReportId } = useAppContext();
  const { values, errors, touched, handleChange, handleSubmit, setFieldValue } =
    useFormik<TFormValues>({
      initialValues: {
        month: '',
        year: '',
        lastName: '',
        reportType: EReportType.PERSONAL,
        reportFile: null,
        isFileValid: true,
      },
      validationSchema: mainDataStepScheme,
      onSubmit: async (values) => {
        try {
          const formattedData = formatDataToSubmit(values);

          const { data } = await api.expense.createReport(formattedData);

          setReportId(data.id);

          onHandleNextStep();
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log(err);
        }
      },
    });

  const formatDataToSubmit = (values: TFormValues) => ({
    reportType: values.reportType,
    reportFile: values.reportFile,
    newFileName: generateFileName(values),
  });

  return (
    <>
      <form
        className="category-comparison__main-data-form"
        id="main-data-form"
        onSubmit={handleSubmit}
      >
        <div className="category-comparison__main-data-form-row">
          <div className="category-comparison__main-data-form-row-name">
            Select One:
          </div>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="report-type"
              name="reportType"
              value={values.reportType}
              onChange={handleChange}
              className="category-comparison__main-data-form-radio-group"
            >
              {radioButtonsToRender.map((element) => (
                <FormControlLabel
                  value={element.value}
                  key={element.label}
                  control={
                    <Radio
                      checkedIcon={<RadioCheckedIcon />}
                      icon={<RadioIcon />}
                    />
                  }
                  label={element.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </div>

        <div className="category-comparison__main-data-form-row">
          <div className="category-comparison__main-data-form-row-name">
            Select Statement Date:
          </div>
          {selectsToRender.map((element) => (
            <Fragment key={element.id}>
              <FormControl className={element.className}>
                <InputLabel
                  className="category-comparison__main-data-form-input"
                  id="month-select"
                >
                  {element.label}
                </InputLabel>
                <Select
                  id={element.id}
                  value={values[element.name as keyof TFormValues]}
                  name={element.name}
                  onChange={handleChange}
                  label={element.label}
                  IconComponent={ChevronDownIcon}
                  className="category-comparison__main-data-form-select"
                  MenuProps={menuProps}
                  error={Boolean(
                    errors[element.name as keyof typeof errors] &&
                      touched[element.name as keyof typeof touched],
                  )}
                >
                  {element.menuItems.map((name) => (
                    <StyledMenuItem
                      className="category-comparison__main-data-form-select-item"
                      key={name}
                      value={name}
                    >
                      {name}
                    </StyledMenuItem>
                  ))}
                </Select>
              </FormControl>
            </Fragment>
          ))}
        </div>

        <div className="category-comparison__main-data-form-row">
          <div className="category-comparison__main-data-form-row-name">
            Last Name:
          </div>
          <TextField
            name="lastName"
            placeholder="Enter the name of the final report file"
            className="category-comparison__main-data-form-textfield"
            variant="outlined"
            value={values.lastName}
            onChange={handleChange}
            error={Boolean(touched.lastName && errors.lastName)}
            helperText={Boolean(touched.lastName) && errors.lastName}
          />
        </div>

        <div className="category-comparison__main-data-form-row">
          <div className="category-comparison__main-data-form-row-name">
            File Upload:
          </div>

          <StyledDropzone
            setFieldValue={setFieldValue}
            reportFile={values.reportFile}
            isFileValid={values.isFileValid}
          />
        </div>
      </form>
      <NextButton
        form="main-data-form"
        type="submit"
        className="category-comparison__main-data-next-button"
        disabled={!values.reportFile || !values.isFileValid}
      >
        Next <ArrowRightIcon />
      </NextButton>
    </>
  );
};

export default memo(MainData);

const selectsToRender = [
  {
    className:
      'category-comparison__main-data-form-select category-comparison__main-data-form-select-month',
    label: 'Select a month',
    id: 'month-select',
    name: 'month',
    menuItems: MONTHS,
  },
  {
    className: 'category-comparison__main-data-form-select',
    label: 'Select the year',
    id: 'year-select',
    name: 'year',
    menuItems: years,
  },
];

const radioButtonsToRender = [
  {
    value: EReportType.PERSONAL,
    label: 'Personal',
  },
  {
    value: EReportType.BUSINESS,
    label: 'Business',
  },
];

const menuProps = {
  MenuListProps: {
    style: {
      padding: '0',
    },
  },
  sx: {
    '&& .Mui-selected': {
      backgroundColor: '#EBF8FF',
    },
  },
};
